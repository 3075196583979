<template>
  <div>
    <div class="nav">
      <img src="@/assets/images/Welfare/newbgimg.jpg" />
    </div>
    <div class="navs">
      <img src="@/assets/images/Welfare/newbgimg.jpg" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.nav {
  height: 300px;
  overflow: auto;
  margin-top: 10px;
  margin-left: 30px;
  img {
    width: 500px;
    height: 800px;
  }
}
.navs {
  display: none;
}
@media (max-width: 550px) {
  .navs {
    height: 550px;
    display: block;
    margin-top: 10px;
    img {
      width: 100%;
      height: 500px;
    }
  }
  .nav {
    display: none;
  }
}
</style>
